import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  TablePagination,
} from "@mui/material";
const UsersComponent = ({ modAuthTrue }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const fetchUsers = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      setLoading(false);
      return;
    }

    const { user_email } = decodeToken(token);

    try {
      const response = await fetch("https://api.fullagro.com:8888/users", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        setUsers(data.users);
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError("Error: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  const decodeToken = (token) => {
    try {
      const decoded = jwtDecode(token);
      return decoded;
    } catch (error) {
      console.error("Token decoding failed:", error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    const token = localStorage.getItem("token");
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      const response = await fetch("https://api.fullagro.com:8888/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ username, email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess(data.message);
        window.location.replace("/");
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError("Error: " + err.message);
    }
  };

  const handleDeactivate = async () => {
    if (!selectedUser) return;

    setError("");
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        `https://api.fullagro.com:8888/users/${selectedUser.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSuccess(data.message);
        fetchUsers();
        handleModalClose();
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError("Error: " + err.message);
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    if (!selectedUser) return;

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://api.fullagro.com:8888/users/${selectedUser.id}/password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            newPassword: selectedUser.password,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSuccess(data.message);
        fetchUsers();
        handleModalClose();
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError("Error: " + err.message);
    }
  };
  const filteredUsers = users.filter((user) =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div
      className="register-container"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor:'white'
      }}
    >
      <Typography variant="h4" color="black">
        New User
      </Typography>
      <form className="register-container-form" onSubmit={handleSubmit} style={{alignSelf:'center',width:'80%',display:'flex',flexDirection:'column',flex:'1',justifyContent:'center',alignItems:'center'}}>
        <TextField
          variant="outlined"
          label="Nombre de Usuario"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          variant="outlined"
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          variant="outlined"
          label="Contraseña"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: 20 }}
        >
          Enviar
        </Button>
      </form>

      {error && <div style={{ color: "red" }}>{error}</div>}
      {success && <div style={{ color: "green" }}>{success}</div>}

      <TextField
        variant="outlined"
        fullWidth
        label="Search by Username"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginTop: 20,marginBottom: 20,background:'white' }}
      />

      <Typography variant="h5">Users List</Typography>

      {loading ? (
        <Typography variant="body1">Loading users...</Typography>
      ) : (
        <TableContainer component={Paper} style={{width:'100%',}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Active Status</TableCell>
                <TableCell>Last Logged In</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.is_active ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCell>{user.last_logged_in}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => handleEditUser(user)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{background:'white'}}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Edit User"
      >
        <h2>Edit User</h2>
        {selectedUser && (
          <form onSubmit={handleUpdateUser} className="user-modal-form">
            <div>
              <label>Password:</label>
              <input
                type="password"
                onChange={(e) =>
                  setSelectedUser({ ...selectedUser, password: e.target.value })
                }
                required
              />
            </div>
            <div className="buttons">
              <button type="submit">Update Password</button>
              <button type="button" onClick={handleDeactivate}>
                {selectedUser.is_active ? "Deactivate" : "Activate"}
              </button>
              <button type="button" onClick={handleModalClose}>
                Close
              </button>
            </div>
          </form>
        )}
        {error && <div style={{ color: "red" }}>{error}</div>}
        {success && <div style={{ color: "green" }}>{success}</div>}
      </Modal>
    </div>
  );
};

export default UsersComponent;
