import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Login from "./Components/Login/LoginComponent";
import Register from "./Components/Register/UsersComponent";
import Header from "./Components/Header/HeaderComponent";
import Dashboard from "./Components/Dashboard/DashboardComponent";
import PurchasedProducts from "./Components/Account/PurchasedProducts";
import Modal from "react-modal";
import loadingOrderGif from "./loadingOrder.gif";
import UsersComponent from "./Components/Register/UsersComponent";

function Fullagroapp() {
  const [auth, setAuth] = useState(
    localStorage.getItem("token") ? true : false
  );
  const [showPurchasedProducts, setShowPurchasedProducts] = useState(false);
  const [showCreateUser, setshowCreateUser] = useState(false);
  const [orderSummary, setOrderSummary] = useState([]);
  const [confirmOrderLoader, setConfirmOrderLoader] = useState(false);
  const [modalOrderIsOpen, setModalOrderIsOpen] = useState(false);

  const modAuthTrue = () => setAuth(true);
  const modAuthFalse = () => {
    setAuth(false);
    setShowPurchasedProducts(false);
    setOrderSummary([]);
  };

  const togglePurchasedProducts = () => {
    setShowPurchasedProducts((prev) => !prev);
  };

  const toggleCreateUser = () => {
    setshowCreateUser((prev) => !prev);
  };

  const openOrderModal = () => {
    if (orderSummary.length > 0) {
      setModalOrderIsOpen(true);
    } else {
      alert("No products added to the order");
    }
  };

  const closeOrderModal = () => {
    setModalOrderIsOpen(false);
  };

  const handleConfirmOrder = async () => {
    setConfirmOrderLoader(true);
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("https://api.fullagro.com:8888/orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          orders: orderSummary,
        }),
      });

      if (response.ok) {
        alert(
          "Orders created successfully, you will receive a copy via email."
        );
        setOrderSummary([]);
        window.location.reload();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error creating orders:", error.message);
      if (error.message.includes("401")) {
        alert("Invalid session. Please log in again.");
      } else {
        alert("There was an error creating the orders. Please try again.");
      }
    } finally {
      setConfirmOrderLoader(false);
      setModalOrderIsOpen(false);
    }
  };

  const handleQuantityChange = (index, newQuantity) => {
    setOrderSummary((prevOrderSummary) =>
      prevOrderSummary.map((item, i) =>
        i === index ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const handleRemoveProduct = (index) => {
    setOrderSummary((prevOrderSummary) =>
      prevOrderSummary.filter((_, i) => i !== index)
    );
  };

  return (
    <Router>
      <div id="app">
        <Header
          auth={auth}
          modAuthFalse={modAuthFalse}
          togglePurchasedProducts={togglePurchasedProducts}
          orderSummary={orderSummary}
          openOrderModal={openOrderModal}
          toggleCreateUser={toggleCreateUser}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                auth={auth}
                showPurchasedProducts={showPurchasedProducts}
                showCreateUser={showCreateUser}
                modAuthTrue={modAuthTrue}
                modAuthFalse={modAuthFalse}
                setOrderSummary={setOrderSummary}
                orderSummary={orderSummary}
              />
            }
          />
        </Routes>

        {confirmOrderLoader && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              zIndex: 9999,
            }}
          >
            <h2>
              <b>Creating Order.</b>
            </h2>
            <img
              src={loadingOrderGif}
              alt="Loading..."
              style={{
                width: "6rem",
                height: "6rem",
              }}
            />
          </div>
        )}

        <Modal
          isOpen={modalOrderIsOpen}
          onRequestClose={closeOrderModal}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "60%", // Un poco más ancho para acomodar la tabla,
              textAlign:"center",
              background:"rgb(216, 228, 214)"
            },
          }}
          contentLabel="Order Summary"
        >
          <h2>Order Summary</h2>
          {orderSummary.length > 0 ? (
            <table style={{ width: "100%", borderCollapse: "collapse" , textAlign:"center", }}>
              <thead>
                <tr>
                  
                  <th style={{ border: "1px solid #000", padding: "8px" }}>
                    Strain
                  </th>
                  <th style={{ border: "1px solid #000", padding: "8px" }}>
                    Batch ID
                  </th>
                  <th style={{ border: "1px solid #000", padding: "8px" }}>
                    Quantity
                  </th>
                  <th style={{ border: "1px solid #000", padding: "8px" }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderSummary.map((item, index) => (
                  <tr key={index}>
                    
                    <td style={{ border: "1px solid #000", padding: "8px" }}>
                      {item.strain}
                    </td>
                    <td style={{ border: "1px solid #000", padding: "8px" }}>
                      {item.batchID}
                    </td>
                    <td style={{ border: "1px solid #000", padding: "8px" }}>
                      <input
                        type="number"
                        min="0"
                        value={item.quantity}
                        onChange={(e) =>
                          handleQuantityChange(
                            index,
                            parseInt(e.target.value) || 0
                          )
                        }
                        style={{ width: "60px" }}
                      />
                    </td>
                    <td style={{ border: "1px solid #000", padding: "8px" }}>
                      <button onClick={() => handleRemoveProduct(index)}>
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No products added to the order.</p>
          )}
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button onClick={handleConfirmOrder}>Confirm Order</button>
            <button onClick={closeOrderModal}>Cancel</button>
          </div>
        </Modal>
      </div>
    </Router>
  );
}

function Home({
  auth,
  showPurchasedProducts,
  showCreateUser,
  modAuthTrue,
  modAuthFalse,
  setOrderSummary,
  orderSummary,
}) {
 

  if (!auth) {   
    return <Login modAuthTrue={modAuthTrue} />;
  }
  

  if (showPurchasedProducts){
    return <PurchasedProducts />
  }else if (showCreateUser){
    return <UsersComponent />
  }else{
    return <Dashboard
      setOrderSummary={setOrderSummary}
      orderSummary={orderSummary}
      modAuthFalse={modAuthFalse}
    />
  }
      
}

export default Fullagroapp;
