import React from "react";
import "../../index.css";
import {jwtDecode} from 'jwt-decode';

function Header({ auth, modAuthFalse, togglePurchasedProducts, toggleCreateUser ,orderSummary, openOrderModal }) {

  const decodeToken = (token) => {
    try {
      const decoded = jwtDecode(token);
      return decoded;
    } catch (error) {
      console.error('Token decoding failed:', error);
      return null;
    }
  };

  const logout = () => {
    modAuthFalse();
    localStorage.removeItem('token');
  };

  return (
    <div className="header">
      <div className="logo">
        <svg
          width="179"
          height="32"
          viewBox="0 0 179 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 21.8958V0.29532H13.368V3.10086H3.25254V9.68227H12.4102V12.4773H3.25254V21.8958H0Z"
            fill="#E5EDE5"
          />
          <path
            d="M31.2307 0.29532H34.4937V14.5023C34.4937 16.0141 34.1393 17.3535 33.4306 18.5208C32.7218 19.6809 31.7254 20.595 30.4412 21.263C29.157 21.924 27.6518 22.2544 25.9256 22.2544C24.2063 22.2544 22.7046 21.924 21.4204 21.263C20.1363 20.595 19.1398 19.6809 18.431 18.5208C17.7223 17.3535 17.3679 16.0141 17.3679 14.5023V0.29532H20.6204V14.2386C20.6204 15.216 20.8345 16.0844 21.2625 16.8438C21.6976 17.6032 22.3116 18.2008 23.1046 18.6368C23.8975 19.0657 24.8379 19.2802 25.9256 19.2802C27.0203 19.2802 27.9641 19.0657 28.757 18.6368C29.557 18.2008 30.1675 17.6032 30.5886 16.8438C31.0166 16.0844 31.2307 15.216 31.2307 14.2386V0.29532Z"
            fill="#E5EDE5"
          />
          <path
            d="M39.2225 21.8958V0.29532H42.4751V19.0903H52.2432V21.8958H39.2225Z"
            fill="#E5EDE5"
          />
          <path
            d="M55.9536 21.8958V0.29532H59.2062V19.0903H68.9743V21.8958H55.9536Z"
            fill="#E5EDE5"
          />
          <path
            d="M82.3502 21.8958H78.8977L86.6553 0.29532H90.4131L98.1708 21.8958H94.7183L88.6237 4.21885H88.4553L82.3502 21.8958ZM82.9291 13.437H94.1288V16.1793H82.9291V13.437Z"
            fill="#E5EDE5"
          />
          <path
            d="M114.86 7.11931C114.656 6.47945 114.383 5.90639 114.039 5.40013C113.702 4.88684 113.298 4.45089 112.828 4.09229C112.358 3.72665 111.821 3.44891 111.218 3.25906C110.621 3.06922 109.965 2.97429 109.249 2.97429C108.035 2.97429 106.941 3.28719 105.965 3.91299C104.99 4.53878 104.218 5.45638 103.65 6.66579C103.088 7.86816 102.808 9.34124 102.808 11.085C102.808 12.8359 103.092 14.316 103.66 15.5254C104.229 16.7348 105.007 17.6524 105.997 18.2782C106.986 18.904 108.113 19.2169 109.376 19.2169C110.548 19.2169 111.569 18.9778 112.439 18.4997C113.316 18.0215 113.993 17.3465 114.47 16.4746C114.955 15.5957 115.197 14.5621 115.197 13.3738L116.039 13.532H109.87V10.8425H118.344V13.2999C118.344 15.114 117.958 16.6891 117.186 18.025C116.421 19.354 115.362 20.3806 114.007 21.1048C112.66 21.829 111.116 22.1912 109.376 22.1912C107.425 22.1912 105.713 21.7412 104.239 20.8411C102.772 19.9411 101.629 18.6649 100.808 17.0125C99.9865 15.3531 99.576 13.3843 99.576 11.1061C99.576 9.38343 99.8146 7.83652 100.292 6.46539C100.769 5.09426 101.439 3.93056 102.302 2.97429C103.172 2.01099 104.193 1.2762 105.365 0.769941C106.544 0.256647 107.832 0 109.228 0C110.393 0 111.477 0.17227 112.481 0.516809C113.491 0.861349 114.39 1.35003 115.176 1.98286C115.969 2.61569 116.625 3.36805 117.144 4.23995C117.663 5.10481 118.014 6.0646 118.197 7.11931H114.86Z"
            fill="#E5EDE5"
          />
          <path
            d="M122.473 21.8958V0.29532H130.157C131.827 0.29532 133.213 0.583608 134.314 1.16018C135.423 1.73676 136.251 2.53483 136.799 3.55438C137.346 4.56691 137.62 5.73764 137.62 7.06658C137.62 8.38849 137.342 9.55219 136.788 10.5577C136.241 11.5561 135.413 12.3331 134.304 12.8886C133.202 13.4441 131.816 13.7218 130.146 13.7218H124.325V10.9163H129.851C130.904 10.9163 131.76 10.7651 132.42 10.4628C133.086 10.1604 133.574 9.72094 133.883 9.14436C134.192 8.56779 134.346 7.87519 134.346 7.06658C134.346 6.25093 134.188 5.54428 133.872 4.94661C133.564 4.34893 133.076 3.89189 132.409 3.57548C131.75 3.25203 130.883 3.09031 129.809 3.09031H125.725V21.8958H122.473ZM133.115 12.1503L138.441 21.8958H134.736L129.515 12.1503H133.115Z"
            fill="#E5EDE5"
          />
          <path
            d="M160.303 11.0956C160.303 13.4019 159.882 15.3847 159.04 17.0442C158.198 18.6965 157.044 19.9692 155.577 20.8622C154.117 21.7482 152.458 22.1912 150.598 22.1912C148.732 22.1912 147.065 21.7482 145.598 20.8622C144.139 19.9692 142.988 18.693 142.146 17.0336C141.304 15.3742 140.883 13.3949 140.883 11.0956C140.883 8.78928 141.304 6.80993 142.146 5.15755C142.988 3.49813 144.139 2.22544 145.598 1.33949C147.065 0.446495 148.732 0 150.598 0C152.458 0 154.117 0.446495 155.577 1.33949C157.044 2.22544 158.198 3.49813 159.04 5.15755C159.882 6.80993 160.303 8.78928 160.303 11.0956ZM157.082 11.0956C157.082 9.33773 156.798 7.85761 156.23 6.65524C155.668 5.44584 154.896 4.53175 153.914 3.91299C152.938 3.28719 151.833 2.97429 150.598 2.97429C149.356 2.97429 148.247 3.28719 147.272 3.91299C146.297 4.53175 145.525 5.44584 144.956 6.65524C144.395 7.85761 144.114 9.33773 144.114 11.0956C144.114 12.8534 144.395 14.3371 144.956 15.5465C145.525 16.7488 146.297 17.6629 147.272 18.2887C148.247 18.9075 149.356 19.2169 150.598 19.2169C151.833 19.2169 152.938 18.9075 153.914 18.2887C154.896 17.6629 155.668 16.7488 156.23 15.5465C156.798 14.3371 157.082 12.8534 157.082 11.0956Z"
            fill="#E5EDE5"
          />
          <path
            d="M174.474 8.05801L171.148 0.29532H172.653L174.895 5.80092L177.126 0.29532H178.632L175.316 8.05801H174.474ZM170.779 8.05801V0.29532H172.327V8.05801H170.779ZM177.453 8.05801V0.29532H179V8.05801H177.453ZM165.527 8.05801V0.970336H167.074V8.05801H165.527ZM163.169 1.62426V0.29532H169.432V1.62426H163.169Z"
            fill="#E5EDE5"
          />
          <path
            d="M13.7645 29.321V32H0.0701733V29.321H13.7645Z"
            fill="#24FF00"
          />
          <path d="M27.3983 29.321V32H13.704V29.321H27.3983Z" fill="#24FF00" />
        </svg>
      </div>
      <div className="menu">
        {auth && (
          <>
            <button
              id="order-button"
              onClick={openOrderModal}              
            >
              <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke="#292d32" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"><path d="m2 2h1.74001c1.08 0 1.92999.93 1.83999 2l-.83 9.96c-.14 1.63 1.14999 3.03 2.78999 3.03h10.65001c1.44 0 2.7-1.18 2.81-2.61l.54-7.5c.12-1.66-1.14-3.01-2.81-3.01h-12.90999"/><path d="m16.25 22c.6904 0 1.25-.5596 1.25-1.25s-.5596-1.25-1.25-1.25-1.25.5596-1.25 1.25.5596 1.25 1.25 1.25z"/><path d="m8.25 22c.69036 0 1.25-.5596 1.25-1.25s-.55964-1.25-1.25-1.25-1.25.5596-1.25 1.25.55964 1.25 1.25 1.25z"/><path d="m9 8h12"/></g></svg>
              <b>{orderSummary.length}</b>
            </button>
            {decodeToken(localStorage.getItem('token')).user_email === 'david@fullagro.com' ? <button onClick={toggleCreateUser}>USERS</button> : undefined}
            <button onClick={togglePurchasedProducts}>ACCOUNT</button>                                    
            <button onClick={logout}>LOG OUT</button>
          </>
        )}
      </div>
    </div>
  );
}

export default Header;